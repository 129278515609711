.container {
  position: relative;

  width: 100%;
  min-height: 260px;
  padding: 24px;

  border-radius: 16px;
  background: var(--palette-default);

  @media screen and (min-width: 768px) {
    grid-column: 2/3;
    grid-row: 2/4;
  }
}

.title {
  margin: 0;

  color: var(--grey-body);
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.chartContainer {
  margin: 0 -24px;
}

.chartContentWrapper {
  width: 100%;
  height: 300px;
  display: grid;
  place-items: center;

  span {
    color: var(--palette-text-secondary);
  }
}
