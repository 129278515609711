.wrapper {
  margin-top: 24px;
  margin: 0 -15px;
}

.list {
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease;
  opacity: 1;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 1px solid var(--palette-tertiary-transparent);
  transition: 0.2s ease;

  &:last-child {
    border-bottom: none;
  }
}

.itemActive {
  background-color: #efefef;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.colorBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.details {
  font-size: 12px;
  color: var(--palette-grey-main);
}

.loadingList {
  @extend .list;
  opacity: 0.5;
}
