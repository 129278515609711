.container {
  position: relative;

  width: 100%;
  min-height: 260px;
  padding: 24px;

  border-radius: 16px;
  background: var(--palette-default);

  @media screen and (min-width: 768px) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}

.title {
  margin: 0 0 12px 0;

  color: var(--grey-body);
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 8px;
}

.select {
  height: 36px !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
}

.button {
  display: flex;
  flex-direction: row;
  gap: 6px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  div {
    width: 14px;
    height: 14px;
    border: 1px solid var(--palette-grey-main);
    border-radius: 50%;
    padding: 1px;
  }

  span {
    font-size: 12px;
  }
}

.selected {
  background-color: #1a65b5;
}
