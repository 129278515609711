.container {
  position: relative;

  width: 100%;
  min-height: 260px;
  padding: 24px;

  border-radius: 16px;
  background: var(--palette-default);

  @media screen and (min-width: 768px) {
    grid-column: 1/3;
  }
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.dateWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.title {
  margin: 0;

  color: var(--grey-body);
}
