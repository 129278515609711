.timeSwitcherGroup {
  display: flex;

  border: 1px solid var(--palette-primary-disabled);
  border-radius: 4px;
}

.timeSwitcher {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--palette-grey-main);
  font-size: 12px;

  margin: 0;
  padding: 2px 12px;

  cursor: pointer;
  transition: background-color 0.2s;

  border: none;
  border-right: 1px solid var(--palette-primary-disabled);
  outline: none;
  background-color: transparent;
  appearance: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:last-child {
    border-right: none;
  }
}

.active {
  background-color: var(--palette-primary-transparent);
}
