.container {
  padding: 6px 10px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 30px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.errorMessage {
  margin: 8px 0 12px 0;
}

.passwordError {
  height: auto !important;
  min-height: 24px !important;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions button:first-of-type {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 100%;
  }
}
