.hintWrapper {
  position: relative;

  display: flex;
  align-items: center;

  margin: 0 auto 11px auto;
  padding: 8px 20px;

  border-radius: 6px;
}

.borderWrapper {
  position: absolute;
  left: 0;

  width: 7px;
  height: 100%;

  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.success {
  background: var(--palette-success-transparent);
}

.error {
  background: var(--palette-error-transparent);
}

.warning {
  background: var(--palette-warning-transparent);
}

.info {
  color: var(--palette-info);
  background: #e1f1fd;
}

.success .borderWrapper {
  background: var(--palette-success);
}

.warning .borderWrapper {
  background: var(--palette-warning);
}

.info .borderWrapper {
  background: var(--palette-info-hover);
}

.error .borderWrapper {
  background: var(--palette-error);
}

.hint {
  line-height: 19px;
}

@media (max-width: 768px) {
  .hintWrapper {
    width: 100%;
  }
}
