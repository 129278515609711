.row {
  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: center;
  gap: 0 20px;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 100%;
  }
}

/* Days preview */

.preview {
  display: grid;

  margin: 10px 0 20px;
  padding: 6px;

  border-radius: 10px;
  background: var(--palette-default-hover);
  gap: 2px;
}

.day {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  min-height: 36px;
  padding: 6px 24px;

  background: var(--palette-default);
}

.day:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.day:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.day > span {
  margin-right: 7px;

  color: var(--palette-primary);

  font-weight: 500;
}

.day .chips {
  display: flex;
  flex-wrap: wrap;
}

.day .chips > * {
  margin-left: 7px;
}

/* Loader */

.loader {
  display: grid;
  align-items: center;

  margin-top: -2px;
  padding: 4px 0;

  font-size: 11px;
  grid-template-columns: 25px 1fr;
}
