.wrapper {
  width: 100%;

  margin-bottom: 12px;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.label {
  padding-left: 11px;
}

.optionButton {
  height: 32px;

  padding: 4px 32px;

  cursor: pointer;

  transition: background-color 0.1s ease;

  color: var(--palette-primary);
  border: 1px solid var(--palette-primary);
  background-color: var(--palette-default);
}

.optionButton:focus {
  background-color: var(--palette-default-focus);
}

.optionButton:hover {
  background-color: var(--palette-default-hover);
}

.optionButton:first-child {
  border-right: none;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.optionButton:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.activeButton {
  color: var(--palette-default);
  background-color: var(--palette-primary);
}

.activeButton:focus {
  background-color: var(--palette-primary);
}

.activeButton:hover {
  background-color: var(--palette-primary);
}
