.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.container .form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  max-width: 1200px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  gap: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 30px;
}

.actions :global(.checkbox__label) {
  max-width: 300px;

  white-space: normal !important;
  word-wrap: break-word;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
}

.example {
  display: flex;
  justify-content: center;
}

.example img {
  max-width: 100%;
}

.checkbox {
  gap: 12px;
}

.additionalInfo {
  color: var(--palette-text-default);
}

.additionalInfo span {
  font-weight: 500;
}

@media (max-width: 992px) {
  .fileList {
    grid-template-columns: 100%;
  }

  .example {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
