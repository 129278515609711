.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 360px minmax(450px, auto) auto;
    gap: 12px;
  }
}

:global(.recharts-yAxis .recharts-cartesian-axis-tick-value) {
  font-size: 10px;
  fill: var(--palette-text-secondary);
}

:global(.recharts-xAxis .recharts-cartesian-axis-tick-value) {
  font-size: 10px;
  fill: var(--palette-text-secondary);
}

:global(.recharts-cartesian-grid-horizontal line) {
  stroke: var(--palette-tertiary-transparent);

  opacity: 0.4;
}

:global(.recharts-cartesian-grid-vertical line) {
  stroke: var(--palette-tertiary-transparent);

  opacity: 0.4;
}

:global(.recharts-layer.recharts-cartesian-axis line) {
  stroke: var(--palette-tertiary-transparent);
}

:global(.recharts-reference-line line) {
  stroke: var(--palette-tertiary-transparent);
}
