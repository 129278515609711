/* User */

.user {
  display: flex;
  align-items: center;
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin: 0 5px 0 0;

  border: 1px solid var(--palette-grey-table-bg);
  border-radius: 10px;
}

.userName {
  display: -webkit-box;
  overflow: hidden;

  max-width: 160px;

  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.menuButton svg,
.menuButton img {
  width: 20px;
  margin-right: 4px !important;
}

/* Date */

.date {
  width: 100%;
  max-width: 220px;

  white-space: nowrap;
}

.date img {
  margin: 0 6px 0 14px;
}

.date img:first-child {
  margin: 0 6px 0 0;
}

.lockIcon {
  margin-right: 8px;
  margin-left: 3px;
}
