.tooltipButtons {
  padding: 8px 14px !important;

  background-color: var(--button-primary-background) !important;

  font-size: 13px !important;
}

.buttons {
  display: flex;
}

.pagination {
  overflow-x: auto;
}

.modal {
  width: 456px !important;
  padding: 6px 32px !important;
}

.addBtn:hover {
  background-color: var(--button-primary-active-background) !important;
}

.addBtn:focus {
  background-color: var(--button-primary-background) !important;
}

@media (max-width: 1024px) {
  .search {
    width: 300px !important;
  }
}

@media (max-width: 992px) {
  .buttons {
    display: none;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 100% !important;
    padding: 24px 40px !important;
  }

  .search {
    width: 100%;
  }

  button.addBtn {
    padding: 0 10px;
  }

  .addBtnText {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
