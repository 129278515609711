.tooltipWrapper {
  width: 186px;
  padding: 8px;

  border-radius: 10px;
  background: var(--palette-default);
  box-shadow: 0 0 6px var(--palette-shadow-small);
  border: 1px solid #cacaca;
  margin-left: 12px;
}

.title {
  margin: 0;
  font-size: 14px;
  padding-bottom: 12px;
}

.usage {
  font-size: 12px;
  margin-bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 6px;

  p {
    margin: 0;
  }
}

.colorBox {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  flex-shrink: 0;
}

.tooltipDate {
  margin-bottom: 5px;

  color: var(--palette-text-default);

  font-weight: 600;
}

.tooltipItem {
  display: flex;
  align-items: center;

  padding: 3px 2px;
}

.tooltipCaptionWrap {
  display: flex;
  align-items: baseline;

  min-width: 100px;
  max-width: 100px;
  margin-right: 10px;
}

.tooltipVisualSign {
  width: 12px;
  height: 12px;
  margin-right: 6px;

  border-radius: 2px;
}

.tooltipDivider {
  margin: 8px 0;

  border: 1px solid var(--palette-primary-disabled);
}

.tooltipFrozenInfo {
  padding: 4px;

  border-radius: 4px;

  background-color: var(--palette-primary-transparent);
}
