.container {
  padding: 24px 0;
}

.errorMsg {
  position: absolute;
  top: 28px;

  width: 80%;
  max-width: 80%;
}

.title {
  margin-bottom: 24px;

  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.buttons > button {
  width: 146px !important;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
}

.buttonsWrapper > button:last-child {
  margin-left: 34px;
}

.input {
  margin: 0 auto;
}

@media (max-width: 768px) {
  .input {
    width: 100%;
  }

  .buttons > button {
    width: auto !important;
  }
}
