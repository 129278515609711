.toolbar {
  display: flex;

  margin-bottom: 30px;
}

.spacer {
  flex: 1 1 auto;
}

.tooltipButtons {
  padding: 8px 14px !important;

  background-color: var(--button-primary-background) !important;

  font-size: 13px !important;
}

.btnMenu,
.btn {
  padding: 8px 16px !important;
}

.tooltipButtons:hover {
  background-color: var(--button-primary-active-background) !important;
}

.btn {
  color: var(--palette-text-secondary) !important;
}

.btn:hover {
  color: var(--button-primary-hover-background) !important;
}

.btn:hover > img {
  filter: contrast(1%);
}

.buttons {
  display: flex;
}

.inputDate > div:first-child {
  flex-wrap: nowrap !important;
}

.pagination {
  overflow-x: auto;
}

.modal {
  padding: 24px 35px !important;
}

.listTypeModal {
  width: 456px !important;
  padding: 6px 32px !important;
}

.search {
  margin-right: 10px;
}

@media (max-width: 1180px) {
  .search {
    width: 300px !important;
  }

  .btn span {
    display: none;
  }
}

@media (max-width: 1024px) {
  .search {
    width: 300px !important;
  }

  .btn {
    padding: 8px 12px !important;

    font-size: 13px !important;
  }
}

@media (max-width: 992px) {
  .buttons {
  }

  .btn span {
    display: block;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 100% !important;
  }

  .listTypeModal {
    width: 100% !important;
    padding: 24px 40px !important;
  }

  .search {
    width: 100%;
  }

  .toolbar button {
    padding: 0 10px;

    font-size: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
