.container {
  padding: 48px;

  border-radius: 16px;
  background: var(--palette-default);
}

.modal {
  padding: 24px 15px !important;
}

.uploadZone {
  margin-bottom: 30px;
}

.container .form {
  max-width: 600px;
  margin: 0 auto;
}

.fileList {
  display: grid;
  gap: 30px;
  grid-template-columns: 100%;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 30px;
}

.actions :global(.checkbox__label) {
  max-width: 300px;

  white-space: normal !important;
  word-wrap: break-word;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  margin-top: 30px;
}

.buttons button:last-child {
  margin-left: 15px;
}

.column {
  display: grid;
  gap: 7px;
}

.additionalInfo {
  color: var(--palette-text-default);

  font-size: 13px;
  font-style: normal;
  line-height: 130%;
}

.additionalInfo span {
  font-weight: 500;
}

@media (max-width: 768px) {
  .container {
    padding: 24px;
  }
}

@media (max-width: 400px) {
  .container {
    padding: 20px 14px;
  }
}
