.id {
  color: var(--palette-text-secondary);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.actionsMobile {
  justify-content: center;

  width: 38px;
}

.mobileWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 95px;
}

/* Info */

.info {
  min-width: 80px;
}

.databases {
  justify-content: center;

  min-width: 80px;

  text-align: center;
}

.long {
  max-width: 200px;
}

/* Date */

.date {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;

  max-width: 200px;

  white-space: nowrap;
}

.column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;
}

.dateItem {
  display: flex;
  align-items: center;

  flex-direction: row;
}

.fileSize {
  color: var(--palette-text-secondary);

  font-size: 11px;
  font-weight: 400;
}

.startAtDate {
  width: 100%;
  min-width: 120px;
  max-width: 200px;

  white-space: nowrap;
}

.date img,
.startAtDate img {
  margin: 0 6px 0 0;
}

.date img:not(:first-child),
.startAtDate img:not(:first-child) {
  margin-left: 12px;
}

/* ETA */

.eta {
  min-width: 120px;
}

/* Error Tooltip */

.errorTooltip {
  max-width: 400px;
  padding: 12px;

  text-align: left;
  word-break: break-word;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 130%;
}

.errorTitle {
  margin-bottom: 12px;

  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 130%;
}

/* Chip */

.cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cell button {
  padding: 0;
}

.chip {
  padding: 0 14px;
}

.chip span::first-letter {
  text-transform: uppercase;
}

.mobileChip {
  padding: 0 10px !important;
}

.highlight > div,
.highlight > div > div {
  background: var(--palette-default-active);
}

.downloadBtn {
  width: 25px;
  padding: 0;
}
