/* Row */

.value {
  display: -webkit-box;
  overflow: hidden;

  max-width: 180px;

  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.capitalizedValue {
  display: -webkit-box;
  overflow: hidden;

  max-width: 180px;

  text-transform: capitalize;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.values {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
}

.date {
  max-width: 150px;
}

.tooltip {
  z-index: 10000 !important;
}

/* Button */

.row button {
  transition: opacity 250ms ease-in-out;

  opacity: 0;
}

.row:hover button {
  opacity: 1;
}

.row button:focus {
  opacity: 1;
}

.chip {
  width: max-content;
}

.createdAt {
  width: 100%;
  max-width: 200px;

  white-space: nowrap;
}

.createdAt img {
  margin: 0 6px 0 0;
}

.createdAt img:not(:first-child) {
  margin-left: 12px;
}
