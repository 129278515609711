.tooltipWrapper {
  width: 156px;
  padding: 8px;

  border-radius: 10px;
  background: var(--palette-default);
  box-shadow: 0 0 6px var(--palette-shadow-small);
}

.title {
  margin: 0;
  font-size: 14px;
}

.usage {
  font-size: 14px;
  margin-bottom: 0;

  span {
    font-weight: bold;
  }
}

.tooltipDate {
  margin-bottom: 5px;

  color: var(--palette-text-default);

  font-weight: 600;
}

.tooltipItem {
  display: flex;
  align-items: center;

  padding: 3px 2px;
}

.tooltipCaptionWrap {
  display: flex;
  align-items: baseline;

  min-width: 100px;
  max-width: 100px;
  margin-right: 10px;
}

.tooltipVisualSign {
  width: 12px;
  height: 12px;
  margin-right: 6px;

  border-radius: 2px;
}

.tooltipDivider {
  margin: 8px 0;

  border: 1px solid var(--palette-primary-disabled);
}

.tooltipFrozenInfo {
  padding: 4px;

  border-radius: 4px;

  background-color: var(--palette-primary-transparent);
}
