.toolbar {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;

  margin-bottom: 30px;
}

.searchContent {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.spacer {
  flex: 1 1 auto;
}

.search {
  margin-right: 10px;
}

@media (min-width: 992px) {
  .toolbar {
    align-items: center;
    flex-direction: row;
    gap: 0;
  }

  .search {
    width: 270px !important;
  }
}

@media (min-width: 1024px) {
  .search {
    width: 320px !important;
  }
}

@media (min-width: 1280px) {
  .search {
    width: 400px !important;
  }
}
