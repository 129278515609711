.values {
  width: 100%;
  min-width: 100px;
  max-width: 300px;
}

.date {
  width: 100%;
  max-width: 200px;

  white-space: nowrap;
}

.date img,
.startAtDate img {
  margin: 0 6px 0 0;
}

.date img:not(:first-child),
.startAtDate img:not(:first-child) {
  margin-left: 12px;
}

.updateItem {
  width: 100%;
}
